$(function () {
    jQuery.validator.addMethod("testEmail", function (value, element) {
        return this.optional(element) || /[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}/i.test(value);
    }, "Digite e-mail valido.");

    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    }, spOptions = {
        onKeyPress: function (val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    };
    $('.celular-input').mask(SPMaskBehavior, spOptions);
    $('.cep').mask('00000-000');

    // Validação de Formularios
    $('#form-fale').validate({
        rules: {
            nome: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true,
                testEmail: true
            },
            telefone: {
                required: true,
                minlength: 14
            }
        },
        messages: {
            nome:{
                required: "Campo obrigatório.",
                minlength: "Insira um nome válido."
            },
            email: {
                required: "Campo obrigatório.",
                email: "Insira um e-mail válido."
            },
            telefone:{
                required: "Campo obrigatório",
                minlength: "Insira um número válido."
            }
        },
        submitHandler: function (form) {
            var dados = $(form).serialize();
            var host = "/lead";
            var actBtn = $(form).find('button[type="submit"]');
            var oldText = actBtn.text();

            actBtn.text('Enviando...');
            actBtn.attr('disabled', true);

            $.ajax({
                type: "POST",
                url: host,
                async: true,
                dataType : "json",
                data: dados,

                success: function (result) {
                    
                    if (result.status == '200') {
                        window.location = "/sucesso-contato";
                    } else {
                        actBtn.text(oldText);
                        actBtn.attr('disabled', false);
                    }

                },
                error: function (data) {
                    actBtn.text(oldText);
                    actBtn.attr('disabled', false);
                }
            });

            return false;
        }
    });

    // Validação de Formularios
    $('#trabalhe-conosco').validate({
        rules: {
            email: {
                required: true,
                email: true,
                testEmail: true
            },
            nome: {
                required: true,
                minlength: 2
            }
        },
        messages: {
            email: {
                required: "",
                email: ""
            },
            nome:{
                required: "",
                minlength: ""
            }
        },
        submitHandler: function (form) {

            var dados = new FormData($(form)[0]);
            var host = "/lead";
            var actBtn = $(form).find('button[type="submit"]');
            var oldText = actBtn.text();

            actBtn.text('Enviando...');
            actBtn.attr('disabled', true);

            $.ajax({
                type: "POST",
                url: host,

                cache: false,
                async:false,

                processData: false, // Don't process the files
                contentType: false, // Set content type to false as jQuery will tell the server its a query string request

                data: dados,

                success: function (result) {
                    
                    if (result.status == '200') {
                        window.location = "/sucesso-trabalhe";
                    }
                    else {
                        actBtn.text(oldText);
                        actBtn.attr('disabled', false);
                    }

                },
                error: function (data) {
                    actBtn.text(oldText);
                    actBtn.attr('disabled', false);
                }
            });

            return false;
        }
    });

    // Validação de Formularios
    $('#te-ligamos-form').validate({
        rules: {
            nome: {
                required: true,
                minlength: 2
            },
            telefone: {
                required: true,
                minlength: 14
            }
        }, messages: {
            nome:{
                required: "",
                minlength: ""
            },
            telefone:{
                required: "",
                minlength: ""
            }
        }, submitHandler: function (form) {
            var dados = $(form).serialize();
            var host = "/lead";
            var actBtn = $(form).find('button[type="submit"]');
            var oldText = actBtn.text();

            actBtn.text('Enviando...');
            actBtn.attr('disabled', true);

            $.ajax({
                type: "POST",
                url: host,
                async: true,
                dataType : "json",
                data: dados,

                success: function (result) {

                    if (result.status == '200') {
                        window.location = "/sucesso-expresso";
                    } else {
                        actBtn.text(oldText);
                        actBtn.attr('disabled', false);
                    }

                },
                error: function (data) {
                    actBtn.text(oldText);
                    actBtn.attr('disabled', false);
                }
            });

            return false;
        }
    });
    $('#simuleSelect').validate({
        submitHandler: function (form) {
            event.preventDefault();

            var tipoSelected = $(form).find('#tipoSimule').val();

            window.location = "/cotacao/" + tipoSelected;
        }
    });

});
