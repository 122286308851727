$(function () {

    $('#goToQuotation').click(function (e) {
        e.preventDefault();
        var form = $('#quotationForm'),
            type = form.find('input[name="type"]').val();

        var automakerId = type == 'automaker' ? $('#automakerInput').data('auto') : $('input[name="automaker"]').val(),
            vehicle = type == 'automaker' ? $('#automakerInput').val() : $('input[name="vehicle"]').val(),
            page = $('#pageId').val();

        form.validate();

        if (form.valid()) {
            window.location.href = '/cotacao/auto?vehicle=' + vehicle + '&automaker=' + automakerId + '&page=' + page;
        }
    });

    // MENU
    $('.desk-menu .with-sub').hover(function () {
        $(this).toggleClass('active');
        $('.sub-sec', this).stop().slideToggle(300);
    });

    $('.desk-menu .sub-sub').hover(function () {
        $('ul', this).stop().slideToggle(300);
    });

    // TOGGLE DO MENU MOBILE
    $('.menu-mobile-toggle').click(function () {
        $('.main-menu-list').slideToggle(300);
    });

    // MENU MOBILE
    $('.mobile-menu .first-level').click(function () {
        var li = $(this).parent('.menu-item');

        $('.mobile-menu li').removeClass('active');
        $(li).addClass('active');
    });

    $('.sub-sub .seta-menu').click(function () {
        var sub = $(this).parents('.sub-sub');

        if ($(sub).hasClass('activesub')) {
            $(sub).removeClass('activesub');
        } else {

            $('.sub-sub').removeClass('activesub');
            $(sub).addClass('activesub');
        }

    });

    $('.back-first-level').click(function () {
        $('.menu-item').removeClass('active');
    });

    $(window).resize(function () {
        changePosition();
        getWindowHeight();
    });

    changePosition();
    getWindowHeight();

    // FORM EXPRESSO
    $('.toggle-form-expresso').click(function () {
        $(this).parent('.form-expresso').toggleClass('active');
    });

    // FORM COTACAO
    $('.steps div').click(function () {
        $(this).addClass('active');

        if (!$(this).next().hasClass('active')) {
            $('.step-item').removeClass('lastborder');
            $(this).addClass('lastborder');
        }
        if (!$(this).prev().hasClass('active')) {
            $('.step-item').removeClass('firstborder');
            $(this).addClass('firstborder');
        }

        if ($(this).not(":first")) {
            // EXIBE O CONTENT
            var dataStep = $(this).attr('data-step');
            $('.step-content').fadeOut(300);
            $('.step-content#' + dataStep).delay(300).fadeIn(300);
        }
    });
    $('.form-next-step span').click(function () {
        var pai = $(this).parents('.step-content');
        var nextId = $(pai).next().attr("id")

        $('.step-content').fadeOut(300);
        $('.step-content#' + nextId).delay(300).fadeIn(300);

        // NAV STEPS DO TOPO
        $('.steps .' + nextId).addClass('active');

        if (!$('.steps .' + nextId).next().hasClass('active')) {
            $('.step-item').removeClass('lastborder');
            $('.steps .' + nextId).addClass('lastborder');
        }
        if (!$('.steps .' + nextId).prev().hasClass('active')) {
            $('.step-item').removeClass('firstborder');
            $('.steps .' + nextId).addClass('firstborder');
        }
    });
    $('.form-prev-step span').click(function () {
        var paiP = $(this).parents('.step-content');
        var prevId = $(paiP).prev().attr("id")

        $('.step-content').fadeOut(300);
        $('.step-content#' + prevId).delay(300).fadeIn(300);

        // NAV STEPS DO TOPO
        $('.steps .' + prevId).addClass('active');

        if (!$('.steps .' + prevId).next().hasClass('active')) {
            $('.step-item').removeClass('lastborder');
            $('.steps .' + prevId).addClass('lastborder');
        }
        if (!$('.steps .' + prevId).prev().hasClass('active')) {
            $('.step-item').removeClass('firstborder');
            $('.steps .' + prevId).addClass('firstborder');
        }
    });

    // OPEN MODAL
    $('.open-modal').click(function () {
        var modal = $(this).attr('data-modal');

        $('.' + modal).fadeIn(300);

        // close
        $('.modal .modal-close').click(function () {
            $('.modal').fadeOut(300);
        });
    });
    $('.open-chat').click(function () {
        $zopim(function () {
            $zopim.livechat.badge.show();
        });
    });
    $('.selectpicker').select2();

    $('#bikeCotacaoModal').on('shown.bs.modal',function (e) {
        var $this = $(this);

        $this.find('.bikeFrame').attr('src', 'https://www.argo-protector.com.br/Bikes/corretor/mazettocor/bikes1');
    });
});

function getWindowHeight() {
    var tela = $(window).height();
    var header = $('header').height();
    var altura = tela - header;

    $('.banner-default').each(function () {
        $(this).css("min-height", altura + "px");
    });
}
function changePosition() {
    var larguraT = $(window).width();

    if (larguraT < 991) {
        $('.simule-home .sec-content').before($('.simule-home .sec-img'));
    } else {
        $('.simule-home .sec-img').before($('.simule-home .sec-content'));
    }
}