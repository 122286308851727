// Serialize Form

/*
 * OBSERVAÇÃO!!!!! NÃO ME ORGULHO DESSE CÓDIGO MAS ELE PRECISOU SER FEITO AS PRESSAS ENTÃO...
 * */
$.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

(function ($) {

    // Quem tava lá
    $(function () {
        quiz.init();

        $('body').addClass('loaded');

    });

})(jQuery);


var quiz = (function () {
    var form = $('#form-step');
    var formQuestions = form.find('#form-questions');
    var formBottomNav = form.find('#question-bottom-nav');
    var loader = $('#pageloader');
    var _token = form.find('input[name="_token"]');

    var currentTab = 0;

    var formTopNav;

    return {
        init: function () {
            var self = this;

            // Bind events
            self.bind();

            var tabs = formTopNav.find('li');

            if (tabs.length == 1) {
                formBottomNav.find('.next').text('Enviar');
                formBottomNav.find('.prev').hide();
            }
        },

        bind: function () {
            var self = this;

            // Enable validation
            formQuestions.find('form').validate();

            // Load Undefined Variables
            formTopNav = formQuestions.find('#question-top-nav');

            // Enable Bottom navigation
            formBottomNav.removeClass('hide');

            // Instancia Bootstrapselect
            formQuestions.find('.selectpicker').select2({
                minimumResultsForSearch: -1
            });

            // Valid the selects
            $("select").on("select2:close", function (e) {
                $(this).valid();
            });

            // Next Prev
            formBottomNav.on('click', function (e) {
                var action = e.target.classList.contains('next') ? 'next' : 'prev';
                var buttonAction = $(e.target);
                var siblingButton = action == 'next' ? $(this).find('.prev') : $(this).find('.next');

                if (currentTab == 0 && action == 'next') {
                    var isNext = self.next();

                    if (isNext)
                        siblingButton.attr('disabled', false);
                }

                else if (currentTab == 1 && action == 'prev') {
                    var isPrev = self.prev();

                    siblingButton.text('Proxima');


                    if (isPrev)
                        buttonAction.attr('disabled', true);
                }

                else if (currentTab > 1 && action == 'prev') {
                    self.prev();

                    siblingButton.text('Proxima');
                }

                else if (action == 'next') {
                    self.next();
                }

            });

            // Bind dependency
            formQuestions.find('form').each(function (key, form) {

                $(form).find('[data-conditionaltarget]').each(function (key1, group) {

                    var $group = $(group);
                    var _waitedValue = $group.data('conditionaltarget-values');
                    var _target = $group.data('conditionaltarget');
                    var _logicOperator = $group.data('conditionaltarget-opr');

                    var dependencyInput = $("[name='" + _target + "']");

                    if (dependencyInput) {
                        dependencyInput.on('change blur', function (e) {

                            if (_logicOperator != null) {
                                if (eval(e.currentTarget.value + _logicOperator + _waitedValue)) {
                                    $group.show();
                                }
                            }
                            else {
                                if ((e.currentTarget.value == _waitedValue || _waitedValue == "*") && e.currentTarget.value != "") {
                                    $group.show();
                                }
                                else {
                                    $group.hide();
                                }
                            }


                        });
                    }
                });

                $(form).find('[data-equal-value-input]').each(function (key2, input) {

                    var $input = $(input);
                    var $targetInput = $("input[name=\"" + $input.data('equal-value-input') + "\"]");

                    $input.after($('<button type="button" class="change_value" data-chained-input="' + $input.attr('name') + '">Alterar</button>'))

                    $targetInput.on('change', function (e) {
                        var $_this = $(this);

                        $input.val($_this.val());
                        $input.attr('disabled', true);
                    });

                });

                $('.change_value').click(function () {

                    var $this = $(this),
                        $targetInput = $("input[name=\"" + $this.data('chained-input') + "\"]");

                    $targetInput.val('');
                    $targetInput.attr('disabled', false);
                    $targetInput.focus();

                    $this.remove();
                });
            });

            // Binds exclusive for auto cotação
            if (window.location.href.indexOf('auto') != -1) {

                formQuestions.find('#automaker_id')
                    .select2({
                        language: "pt-BR",
                        placeholder: "Procurar montadora...",
                        ajax: {
                            url: '/getautomaker',
                            dataType: 'json',
                            delay: 1000,
                            data: function (term, page) {
                                return {
                                    q: term, //search term
                                    page: page // page number
                                };
                            },
                            processResults: function (data, params) {
                                // parse the results into the format expected by Select2
                                // since we are using custom formatting functions we do not need to
                                // alter the remote JSON data, except to indicate that infinite
                                // scrolling can be used
                                params.page = params.page || 1;

                                return {
                                    results: data.items,
                                    pagination: {
                                        more: (params.page * 30) < data.total_count
                                    }
                                };
                            }

                        },
                        minimumInputLength: 1,
                        templateResult: quiz.formatResult,// omitted for brevity, see the source of this page
                        templateSelection: quiz.formatRepoSelection
                    })
                    .on('change blur', function (e) {
                        self.$automaker = $(this).val();

                        self.$url = "/getvehicles?automaker=" + self.$automaker;

                        // Destroy old instance
                        formQuestions.find('#vehicle_id')
                            .select2('destroy')
                            .select2({
                                language: "pt-BR",
                                placeholder: "Procurar por um veículo",
                                minimumInputLength: 1,
                                ajax: {
                                    url: self.$url,
                                    dataType: 'json',
                                    delay: 1000,
                                    data: function (term, page) { // page is the one-based page number tracked by Select2
                                        return {
                                            q: term, //search term
                                            page: page // page number
                                        };
                                    },
                                    processResults: function (data, params) {
                                        // parse the results into the format expected by Select2
                                        // since we are using custom formatting functions we do not need to
                                        // alter the remote JSON data, except to indicate that infinite
                                        // scrolling can be used
                                        params.page = params.page || 1;

                                        return {
                                            results: data.items,
                                            pagination: {
                                                more: (params.page * 30) < data.total_count
                                            }
                                        };
                                    }
                                },

                                templateResult: quiz.formatResult,// omitted for brevity, see the source of this page
                                templateSelection: quiz.formatRepoSelection,
                                escapeMarkup: function (m) {
                                    return m;
                                } // we do not want to escape markup since we are displaying html in results
                            });
                    });

                formQuestions.find('#vehicle_id')
                    .select2({
                        language: "pt-BR",
                        placeholder: "Procurar por um veículo",
                        minimumInputLength: 1,

                        ajax: {
                            url: "/getvehicles?automaker=" + formQuestions.find('#automaker_id').val(),
                            dataType: 'json',
                            delay: 1000,
                            data: function (term, page) { // page is the one-based page number tracked by Select2
                                return {
                                    q: term, //search term
                                    page: page // page number
                                };
                            },
                            processResults: function (data, params) {
                                // parse the results into the format expected by Select2
                                // since we are using custom formatting functions we do not need to
                                // alter the remote JSON data, except to indicate that infinite
                                // scrolling can be used
                                params.page = params.page || 1;

                                return {
                                    results: data.items,
                                    pagination: {
                                        more: (params.page * 30) < data.total_count
                                    }
                                };
                            }
                        },

                        templateResult: quiz.formatResult,// omitted for brevity, see the source of this page
                        templateSelection: quiz.formatRepoSelection,

                        escapeMarkup: function (m) {
                            return m;
                        } // we do not want to escape markup since we are displaying html in results
                    });

                // Especific Binds
                formQuestions.find('select[name="outroscondutorescom18oumais"]').on('change blur', function (e) {

                    var residentesGroup = $('#idadeResidentes');
                    var inputs = residentesGroup.find('.residente');
                    var residentesNum = $(this).val();


                    if (residentesNum != 'nao') {
                        residentesGroup.show();

                        inputs.each(function (key, elm) {
                            if (key + 1 <= residentesNum)
                                $(elm).show();
                            else {
                                $(elm).hide();
                            }
                        });

                    } else {
                        residentesGroup.hide();
                    }
                });
            }

            $('.date-input input[type="date"]').change(function (e) {
                var value = this.value,
                    $parent = $(this).parent();

                if (value != "" && value != null)
                    $parent.addClass('filled');
                else
                    $parent.removeClass('filled');
            });
        },

        getForm: function (formName) {
            var defer = $.Deferred();

            $.get('/cotacao/getForm/' + formName)
                .done(function (result) {
                    if (result.status == 200)
                        defer.resolve(result.data);
                    else
                        throw "Erro no get Form";
                })
                .fail(function (error) {
                    defer.fail(error);
                });

            return defer.promise();
        },

        formatRepoSelection: function (repo) {
            return repo.name || repo.text;
        },

        formatResult: function (data) {
            if (data.loading)
                return data.text;

            return data.name
        },

        validate: function (form) {
            var self = this;

            if (form.length) {
                return form.valid();
            }
        },

        prev: function () {
            var self = this;
            var _currentTab = formTopNav.find('.active');

            _currentTab.closest('li').removeClass('answered');

            formTopNav.find('li:eq(' + --currentTab + ') a').tab('show');

            return true;
        },

        next: function () {
            var self = this;

            // All Tabs
            var next = formTopNav.find('li[role="presentation"]');

            // Procura Aba e Formulário respectivo
            var _currentTab = formTopNav.find('.active').find('a');
            var _currentForm = $(_currentTab.attr('href')).find('form');

            // Verifica se é valido.
            var _isValid = self.validate(_currentForm);

            if (_isValid) {
                _oldCurrent = _currentTab;
                _currentTab = formTopNav.find('li:eq(' + (currentTab + 1) + ') a');

                // Isn't finish?
                if (_currentTab.length) {

                    // Marca a anterior como respondida
                    _oldCurrent.closest('li').addClass('answered');

                    // Se existir
                    ++currentTab;

                    // Find next
                    var _nextTab = formTopNav.find('li:eq(' + (currentTab + 1) + ') a');

                    if (!_nextTab.length)
                        formBottomNav.find('.next').text('Ufa! Terminou.');

                    else
                        formBottomNav.find('.next').text('Próxima');


                    $('html, body').animate({
                        scrollTop: formTopNav.offset().top
                    }, 500);

                    // Ativa a atual
                    _currentTab.tab('show');
                    _currentForm = $(_currentTab.attr('href')).find('form');

                    // Habilita validação atual
                    _currentForm.validate();

                    return true;
                }

                // Is finish
                else {
                    var _arrForms = [];

                    formQuestions.find('form').each(function (key, filledForm) {
                        _arrForms.push($(filledForm).serializeObject());
                    });

                    var actBtn = formBottomNav.find('.next');

                    var oldText = actBtn.text();

                    actBtn.text('Enviando...');
                    actBtn.attr('disabled', true);


                    $.ajax({
                        type: "POST",
                        url: '/cotacao/ufaterminou',
                        async: true,
                        dataType: "json",
                        data: {'_token': _token.val(), form: _arrForms},

                        success: function (result) {
                            var type = window.location.pathname.split('/').pop();

                            if (result.status == '200') {
                                window.location = "/sucesso-cotacao-" + type;
                            }
                            else {
                                actBtn.text(oldText);
                                actBtn.attr('disabled', false);
                            }

                        },
                        error: function (data) {
                            actBtn.text(oldText);
                            actBtn.attr('disabled', false);
                        }
                    });


                }

            }

            return false;
        }
    }
})();
